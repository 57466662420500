import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgThree from "../../assets/images/gallery/infographics/infographic-3.png"
import imgThreeThumb from "../../assets/images/gallery/infographics/infographic-thumb-3.png"
import imgFour from "../../assets/images/gallery/infographics/infographic-4.png"
import imgFourThumb from "../../assets/images/gallery/infographics/infographic-thumb-4.png"
import imgFive from "../../assets/images/gallery/infographics/infographic-5.png"
import imgFiveThumb from "../../assets/images/gallery/infographics/infographic-thumb-5.png"
import imgSix from "../../assets/images/gallery/infographics/infographic-6.png"
import imgSixThumb from "../../assets/images/gallery/infographics/infographic-thumb-6.png"

const infographicsPage = () => (
  <Layout lang="ar">
    <SEO title="Infographics" lang="ar" />
    <main>
        <div className="standard-container">
          <h2 className="relative uk-text-center mt6 mb5">
          انفوجرافيك
            <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
          </h2>
          <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-4@s uk-text-center" data-uk-grid  data-uk-lightbox="animation: slide">
            
            <a href={imgThree}>
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgThreeThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgFour}>
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgFourThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgFive}>
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgFiveThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgSix}>
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgSixThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
          </div>
          <div className="mv5">
          {/* <ul className="uk-pagination uk-flex-center uk-light" data-uk-margin>
            <li><a href="#"><span data-uk-pagination-previous></span></a></li>
            <li><a href="#">1</a></li>
            <li className="uk-disabled"><span>...</span></li>
            <li><a href="#">5</a></li>
            <li><a href="#">6</a></li>
            <li className="uk-active"><span>7</span></li>
            <li><a href="#">8</a></li>
            <li><a href="#"><span data-uk-pagination-next></span></a></li>
          </ul> */}
          </div>   
        </div>
        
    </main>
  </Layout>
)

export default infographicsPage
